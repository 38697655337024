import './App.css';
import WeightedTable from "./WeightedTable";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1 className="playwrite-at-regular text-center text-5xl font-bold m-7">
                    Weighted Average Decision Matrix
                </h1>
            </header>
            <main>
                <WeightedTable />
            </main>
        </div>
    );
}

export default App;
