import React, { useState } from 'react';

const WeightedTable = () => {
    const initChoices = ['Factors', 'choice 1', 'choice 2'];
    const [rows, setRows] = useState([
        { factor: '', weight: '', scores: ['', ''] }
    ]);
    const [choices, setChoices] = useState(initChoices);
    const [showChoice, setShowChoice] = useState([0, 1, 2]);
    const [showTotals, setShowTotals] = useState(false);

    const addColumn = () => {
        setChoices([...choices, `choice ${choices.length}`]);
        setRows(rows.map(row => ({ ...row, scores: [...row.scores, ''] })));
    };

    const removeColumn = () => {
        if (choices.length > 3) {
            setChoices(choices.slice(0, -1));
            setRows(rows.map(row => ({ ...row, scores: row.scores.slice(0, -1) })));
        }
    };

    const addRow = () => {
        setRows([...rows, { factor: '', weight: '', scores: Array(choices.length - 1).fill('') }]);
    };

    const removeRow = () => {
        if (rows.length > 1) {
            setRows(rows.slice(0, -1));
        }
    };

    const handleChange = (rowIndex, field, value, scoreIndex = null) => {
        const newRows = [...rows];
        if (field === 'scores') {
            newRows[rowIndex].scores[scoreIndex] = value;
        } else {
            newRows[rowIndex][field] = value;
        }
        setRows(newRows);
    };

    const handleChoiceUpdate = (e, idx) => {
        const newInputs = [...choices];
        newInputs[idx] = e.target.value || `choice ${idx}`;
        setChoices(newInputs)
        setShowChoice([...showChoice, idx]);
    }

    const shouldShowChoice = (idx) => {
        return showChoice.includes(idx);
    }
    const stopShowingChoice = (idx) => {
        const newValuesToShow = showChoice.filter(value => value !== idx);
        setShowChoice(newValuesToShow);
    }

    const calculateWeightedScore = (weight, score) => {
        const weightNum = parseFloat(weight);
        const scoreNum = parseFloat(score);
        if (!isNaN(weightNum) && !isNaN(scoreNum)) {
            return weightNum * scoreNum;
        }
        return '';
    };

    const calculateTotals = () => {
        return choices.slice(1).map((_, index) =>
            rows.reduce((sum, row) => {
                console.log(row);
                const weightedScore = calculateWeightedScore(row.weight, row.scores[index]);
                return sum + (isNaN(weightedScore) ? 0 : weightedScore);
            }, 0)
        );
    };

    return (
        <div className="p-6 flex items-center justify-center flex-col">
            <table className="w-10/12 table-fixed border-collapse rounded-lg">
                <thead>
                <tr>
                    {choices.map((input, idx) => {
                        return (
                            <th key={idx} className="p-2  border rounded-lg border-gray-400 titillium-web-semibold text-charcoal">
                                {shouldShowChoice(idx) ?
                                    (<button onClick={() => stopShowingChoice(idx)}>{input}</button>)
                                    : (
                                        <input
                                            onFocus={() => stopShowingChoice(idx)}
                                            onBlur={(e) => handleChoiceUpdate(e, idx)}
                                            type="text"
                                            className="m-2 border-2"
                                        />
                                    )
                                }
                            </th>)
                    })}
                </tr>
                </thead>
                <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td className="p-2 border rounded-lg border-gray-400 titillium-web-regular">
                            <input
                                type="text"
                                placeholder="Enter a factor"
                                value={row.factor}
                                onChange={(e) => handleChange(rowIndex, 'factor', e.target.value)}
                                className=" w-full block p-1 bg-pink-50 rounded"
                            />
                            <input
                                type="number"
                                placeholder="Weight (1-10)"
                                min="1"
                                max="10"
                                value={row.weight}
                                onChange={(e) => handleChange(rowIndex, 'weight', e.target.value)}
                                className="w-full block mt-1 p-1 bg-pink-50 rounded"
                            />
                        </td>
                        {row.scores.map((score, scoreIndex) => (
                            <td key={scoreIndex} className="p-2 border rounded-lg border-gray-400">
                                <input
                                    type="number"
                                    placeholder="Enter score (1-10)"
                                    min="1"
                                    max="10"
                                    value={score}
                                    onChange={(e) => handleChange(rowIndex, 'scores', e.target.value, scoreIndex)}
                                    className="w-full p-1 bg-pink-50 rounded"
                                />
                                {calculateWeightedScore(row.weight, score) && (
                                    <span className="block mt-1 text-sm text-pink-600">
                      Weighted: {calculateWeightedScore(row.weight, score)}
                    </span>
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
                {showTotals && (
                    <tr>
                        <td className="p-2 font-bold bg-pink-200 text-pink-800 border border-gray-400 rounded-lg">Totals</td>
                        {calculateTotals().map((total, index) => {
                            console.log(total);
                            return (
                                <td key={index}
                                    className="p-2 font-bold bg-cherry-blossom-200 text-pink-800 border border-gray-400 rounded-lg">
                                    {total.toFixed(2)}
                                </td>
                            )
                        })}
                    </tr>
                )}
                </tbody>
            </table>
            <div className="mt-4 space-x-2">
                <button onClick={addColumn} className="px-4 py-2 bg-green-200 text-green-800 rounded hover:bg-green-300">
                    Add choice
                </button>
                <button onClick={removeColumn} className="px-4 py-2 bg-red-200 text-red-800 rounded hover:bg-red-300">
                    Remove choice
                </button>
                <button onClick={addRow} className="px-4 py-2 bg-blue-200 text-blue-800 rounded hover:bg-blue-300">
                    Add factor
                </button>
                <button onClick={removeRow} className="px-4 py-2 bg-yellow-200 text-yellow-800 rounded hover:bg-yellow-300">
                    Remove factor
                </button>
                <button onClick={() => setShowTotals(true)} className="px-4 py-2 bg-purple-200 text-purple-800 rounded hover:bg-purple-300">
                    Calculate Totals
                </button>
            </div>
        </div>
    );
};

export default WeightedTable;
